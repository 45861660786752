import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { DataService } from '../../services/data.service';
import { AnalyticsService } from '../../analytics.services';
import { Location } from '@angular/common';
import { ToastController} from '@ionic/angular';
@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.scss'],
})
export class ListProductsComponent implements OnInit {
  @Input() previous: any;
  category;
  previouspage;
  headerContent: any = {};
  products = [];
  productsFilter: any;
  inputdata;
  infoEnable;
  mobileUiText;
  deviceLangCode;
  defaultLanguage;
  defaultCountry;
  loading = true;
  Header = '';
  VLTProducts = [];
  VACONProducts = [];
  CATEGORYITEMS = [];
  formType;
  constructor(private readonly router: Router , private location: Location,private toastController: ToastController,private readonly activatedRoute: ActivatedRoute, private dataService: DataService,public analyticsService: AnalyticsService) {

  }
  isMobile;
  Platform = Capacitor.getPlatform();
  ngOnInit() {
    if ((this.Platform === 'android') || (this.Platform === 'ios')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.dataLoad();

  }

  dataLoad() {
    this.dataService.getObject('defaultLangCode').then((defLangCode: any) => {
      this.dataService.deviceLangCode = defLangCode.value;
      this.deviceLangCode = this.dataService.deviceLangCode;
      this.dataService.getObject('defaultLanguage').then((defLang: any) => {
        this.dataService.defaultLanguage = defLang.value;
        this.defaultLanguage = this.dataService.defaultLanguage;
      })

      this.dataService.getObject('listItem').then(prodList => {

        this.dataService.productList = JSON.parse(prodList.value);

      })
      this.dataService.getObject('mobileUiText').then(mobileText => {
        this.dataService.mobileUiText = JSON.parse(mobileText.value);
        this.mobileUiText = this.dataService.mobileUiText;
        this.dataService.settings = this.mobileUiText?.id_7;
        this.dataService.downloads = this.mobileUiText?.id_81;
        this.dataService.home = this.mobileUiText.id_33;
      })
      this.dataService.getObject('defaultCountry').then(defaultCountry => {
        this.defaultCountry = defaultCountry.value;
        // this.defaultCountry = defaultCountry.value != null ? defaultCountry.value : 'all';
        this.dataService.defaultCountry = this.defaultCountry;
        this.activatedRoute.queryParams.subscribe(dataip => {
          this.inputdata = dataip;
          this.analyticsService.setScreenName("/"+dataip.title);
          this.infoEnable = dataip.type === "Type 1" ? false : true;
          const op = false;
this.fetchDataWithRetry()

          this.headerContent = {
            title: this.inputdata.title, info: this.infoEnable, id: this.inputdata.id, itemid: this.inputdata.prodId, PreviousPage: this.inputdata.back, cat: false
          };
        })
      })
    })
  }

  getCategoryProduct(products, categoryName) {

    return products.filter(item => item.product_category_name.includes(categoryName))
  }

  fetchDataWithRetry() {
    const pollInterval = 2000; // Poll every 500ms
    const maxAttempts = 20;   // Define a maximum number of attempts
    let attempts = 0;
    const poll = () => {
      this.dataService.getObject(this.inputdata.id).then(catlist => {
         if (catlist && catlist.value) {
           this.processData(catlist);
         }
         else if (attempts < maxAttempts) {
          attempts++;
          setTimeout(poll, pollInterval); // Retry after interval
        } else {
          this.showToast(this.dataService.mobileUiText?.id_141); // Show toast if max attempts reached
        this.location.back();
        }
      });
    };
    poll();
  }
  async showToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      position: 'bottom'
    });
    toast.present();
  }
  processData(catlist: any) {
    this.products = JSON.parse(catlist.value);
    if (this.products) {
      this.CATEGORYITEMS = Object.values(
        this.products.reduce((a, { product_category_name, product_category_id }) => {
          a[product_category_name] = { product_category_name, product_category_id };
          return a;
        }, {})
      );
      this.products.forEach((element, index) => {
        if (index === 0) {
          this.formType = element.form_type;
        }
      });
      this.loading = false;
    }
  }

  //Navigate To Details Page      
  details(id, typeIp) {
    let href = this.router.url;
    const url = 'home/' + 'productDetails';
    this.router.navigate([url], { queryParams: { id: id, title: this.inputdata.title, catid: this.inputdata.id, type: typeIp, back: href } });
  }

}
