import { Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { ModalController } from '@ionic/angular';
import { LanguageSettingsComponent } from './language-settings/language-settings.component';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { LegalInformationComponent } from './legal-information/legal-information.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  languageDropdown = [];
  languageDefault: Promise<any>;
  countryDefault;
  deviceLangCode;
  regionDefault;
  countryDropdown;
  deviceLang;
  mobileUiText: any = {};
  networkStatus;
  backbtn;
  isMobile;
  appVersion;
  Platform = Capacitor.getPlatform();
  constructor(private readonly router: Router, public modalController: ModalController,
    public dataService: DataService) {
  }

  ionViewDidLeave() {
    this.backbtn.remove();
  }

  async ngOnInit() {

    if ((this.Platform === 'android') || (this.Platform === 'ios')) {
      this.isMobile = true;
      this.appVersion=(await App.getInfo()).version;
    }else {
      this.isMobile = false;  
      
    }
    this.backbtn = App.addListener('backButton', data => {
      this.closeModal();
    })
     this.dataService.getObject('countryDropdown').then(countryDropdown => {
        this.dataService.countryDropdown = JSON.parse(countryDropdown.value);
        this.dataService.countryFullName = this.dataService.countryDropdown.filter(item=>{return item.value===this.dataService.defaultCountry});
      })
    this.mobileUiText = this.dataService.mobileUiText;

    this.languageDefault = this.dataService.defaultLanguage;
    this.languageDropdown = this.dataService.languageDropdown;
    this.countryDropdown = this.dataService.countryDropdown;
    this.countryDefault = this.dataService.defaultCountry;
    this.networkStatus = this.dataService.networkStatus;

  }


  //Backbutton dismiss of Modal to home Page.  
  closeModal() {
    this.modalController.dismiss();
  }
  //Navigate To Home Directly.      
  home() {
    this.router.navigate(['home']);
  }

  customPopoverOptions: any = {
    header: 'Select language',
    cssClass: 'custom-popover',
    width: '100%',
  };
  customAlertOptions: any = {
    header: 'Select the Region'
  }
  async ShowLegalInformationPage(){
    const language = true;
    const modal = await this.modalController.create({
      component: LegalInformationComponent, id: "language", componentProps: {
        value: language,
       contactUs:false
      }
    });
    await modal.present();
  }
  async ContactUsformationPage(){
    const language = true;
    const modal = await this.modalController.create({
      component: LegalInformationComponent, id: "contactUs", componentProps: {
        value: language,
        contactUs:true
      }
    });
    await modal.present();
  }
  async ShowLanguagePage() {
    const language = true;
    const modal = await this.modalController.create({
      component: LanguageSettingsComponent, id: "language", componentProps: {
        value: language,

      }
    });
    await modal.present();

  }
  async ShowCountryPage() {
    const language = false;
    const modal = await this.modalController.create({
      backdropDismiss: false, swipeToClose: true, component: LanguageSettingsComponent, id: "country", componentProps: {
        value: language,

      }
    });
    await modal.present();

  }

}
