// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  host: 'https://mydriveportfolioapp.danfoss.com',
 // host: 'https://danfoss-dev.izserver8.in',
  firebaseConfig : {
    apiKey: "AIzaSyD7v15OiUcTe9FNqG8LcckERZqfW9eWLK8",
    authDomain: "mydrive-portfolio-dev.firebaseapp.com",
    projectId: "mydrive-portfolio-dev",
    storageBucket: "mydrive-portfolio-dev.appspot.com",
    messagingSenderId: "508136463426",
    appId: "1:508136463426:web:5a923c40a453a021b661db",
    measurementId: "G-2NYK5HH49Z"  
}  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
