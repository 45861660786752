import { Injectable } from '@angular/core';
import { DataService } from './services/data.service';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { App } from '@capacitor/app';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { constants } from '../app/constants';
import { Network } from '@capacitor/network';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AlertController, LoadingController } from '@ionic/angular';
import { Geolocation } from '@capacitor/geolocation';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Browser } from '@capacitor/browser';

@Injectable()
export class AppInitService {

  constructor(public diagnostic: Diagnostic, private readonly appVersion: AppVersion,
    public dataService: DataService,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public nativeGeocoder: NativeGeocoder,

  ) {


  }
  ionVersionNumber: any;
  message: any;
  location: any;
  loading: any;
  locationAlert: any;
  isDeniedAlways: any;
  Platform: any;
  gpsEnabled: any;
  gpsSettings: any;
  networkInit = false;
  networkChange: any;
  mobileUiText: any;
  currentDate = new Date();
  appInstall = false;
  androidVersion: any;
  iosVersion: any;
  webVersion: any;
  deviceLangCode:any;
  InstallLoading:any;
  diagonitcs = this.diagnostic.isLocationEnabled;
  optionGeocoder: NativeGeocoderOptions = {
    useLocale: false,
    maxResults: 5
  };
  alert = this.alertController.create(
    {
      header: 'Network Notifiction',
      message: 'Network Offline, Please Enable It',
      backdropDismiss: false,

    });


  Init() {
     if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        //returns installed service workers
        if (registrations.length) {
          for(let registration of registrations) {
            registration.unregister();
         }
        }
 });}
    return new Promise((resolve, reject) => {
      const deviceLang = navigator.language;
      this.deviceLangCode = deviceLang.slice(0, deviceLang.indexOf('-'));
      try {
      Filesystem.stat({path:`cacheImage`,
      directory:Directory.Cache}).then(stat=>{
      }).catch(error=>{
       // if(error.message == 'File does not exist'){
          Filesystem.mkdir({
            path: `cacheImage`,
            directory: Directory.Cache,
            recursive: true
          }); 
      //  }
      })      
    } catch (error) {
      console.error("Unable to make directory CacheImage", error);
      
    }
      Network.getStatus().then(status => {
       if (status.connected) {    
         this.dataService.mobileLanguageApi().then(langdata=>{
          const langlist = langdata.data.filter(i => {
            return i.code === this.deviceLangCode
          })
        
          if(langlist.length >0){          
            this.Appinit().then(data=>{             
              if(data){              
              resolve(true);
            }
            })
          }else{
            this.deviceLangCode="en";
            this.Appinit().then(data=>{             
              if(data){              
                resolve(true);              
              }
            })
          }  
        })  
       }else{
        this.Appinit().then(data=>{             
          if(data){              
            resolve(true);              
          }
        })
      }
      });     
    })
  }
  Appinit(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.Platform = Capacitor.getPlatform();
      this.dataService.getObject('appInstall').then(appins => {
        if (appins.value) {
          this.appInstall = true;
           if ((this.Platform === 'android') || (this.Platform === 'ios')) { 
              App.getInfo().then(data=>
             {
              if (this.Platform === 'android') {
              if(data.version <= '3.0.25'){
                this.dataService.getObject('oldVersion').then(oldApp => {
                  if(!oldApp.value){
                    this.dataService.setObject('oldVersion', 'true').then(res => {
                      this.dataService.appInstall = true;
                      })                
                  }
                 })
                }
              }else if(this.Platform === 'ios'){
              if(data.version <= '3.0.5'){
                this.dataService.getObject('oldVersion').then(oldApp => {
                  if(!oldApp.value){
                    this.dataService.setObject('oldVersion', 'true').then(res => {
                      this.dataService.appInstall = true;
                      })                
                  }
                })
              }
             }
            });
           }  
          }else{
            if(this.Platform == 'web'){
               this.presentInstalLoading();
            }
        }
        if (this.appInstall) {
          this.appInstalledAlready().then(afterins => {
            if (afterins) {
              resolve(true);
            }
          })
        } else {
          // To get initial network status whether available or not    
          Network.getStatus().then(status => {
            if (status.connected) {
              this.dataService.setObject('appInstall', 'true').then(res => {
                this.dataService.setObject('oldVersion', 'true');
                this.installApp().then(instaldata => {
                  resolve(true);

                })
              })
            } else {
              this.splash();
              this.alertPresent();
            }
          })
        }
      })
      this.networkStatusCheck().then(data => {
        if (data) {
          resolve(true);
        }
      })
    })

  }
  alertPresent = async () => {
    (await this.alert).present();
  }
  alertDismiss = async () => {
    (await this.alert).dismiss();

  }
  networkStatusCheck(): Promise<any> {
    return new Promise((resolve) => {
      Network.addListener('networkStatusChange', status => {
        if (status.connected && !this.appInstall && !this.dataService.appInstall) {
          this.alertDismiss();
          this.networkInit = status.connected;
          this.networkChange = status.connected;
          this.networkActiveInit().then(networkStatus => {
            if (networkStatus) {
              resolve(true);
            }
          })
        } else {

        }
      })
    })
  }
  networkActiveInit(): Promise<any> {
    return new Promise((resolve) => {


      Network.getStatus().then(status => {

        if (status.connected) {
          this.networkInit = status.connected;

          this.installApp().then(installdata => {

            if (installdata) {
              resolve(true);
            }
          })

        } else {

          this.splash();
          this.alertPresent();
        }
      });

    })
  }
  appInstalledAlready() {
    return new Promise((resolve) => {
      this.presentLoading();
      this.dataService.getObject('mobileUiText').then(mobileText => {
        this.dataService.mobileUiText = JSON.parse(mobileText.value);
        this.mobileUiText = JSON.parse(mobileText.value);
        Network.getStatus().then(status => {
          if (status.connected) {
            this.dataService.mobileDataSyncInitFunc().then(res => {
               this.dismiss();
              this.dataService.appUpdateVersionCheck().then(appVersionData => {
                this.androidVersion = appVersionData.data.android;
                this.iosVersion = appVersionData.data.ios;
                this.webVersion = appVersionData.data.web;
                this.VersionCheck();
                resolve(true);
              })
            })
          } else {
            resolve(true);
          }
        })
      })
    })
  }
  installApp(): Promise<any> {
    return new Promise((resolve) => {
      this.appInstall = true;
      this.dataService.appInstall=true;
      this.dataService.keyValSetObject('defaultLangCode', this.deviceLangCode);
      try {
        Filesystem.mkdir({
          path: `PDF`,
          directory: Directory.External,
          recursive: true
        }); 
       } catch (error) {
        console.error("Unable to make directory", error);
        
      } 
      this.dataService.mobileAPi(this.deviceLangCode).then(data => {

        this.dataService.getMobileUiText().then(data => {
          this.mobileUiText = data;
          if ((this.Platform === 'android') || (this.Platform === 'ios')) {
            this.initializeCountry().then(countrydata => {
              if (countrydata) {
                resolve(true);
              } else {
                this.dataService.getCountryList().then(countryDropdown => {
                  this.dataService.keyValSetObject('defaultCountry', countryDropdown[0].value).then(data => {

                  this.dataService.defaultCountry = countryDropdown[0].value;
                  this.dataService.keyValSetObject('defaultCountryCode', countryDropdown[0]?.id).then(data => {
                    this.dataService.defaultCountryId = countryDropdown[0]?.id;
                    this.dataService.defaultCountry = countryDropdown[0].value;
                      resolve(true);
                })
  
              })
               
             
            })
              }
            })
          } 
          else {
            this.dataService.mobileCountryApi(this.deviceLangCode).then(countryDropdown => {
              this.getLocationDetails().then(country => {
                let countryvalid = countryDropdown.data.filter(i => i.countries && i.countries.some(i=>i.value===country) )
                if(countryvalid.length){
                    this.dataService.setObject('defaultCountry', countryvalid[0].value)                      
                    this.dataService.defaultCountry = countryvalid[0].value;                      
                    this.dataService.setObject('defaultCountryCode',countryvalid[0].id )
                    this.dataService.defaultCountryId= countryvalid[0].id;                  
                   // this.dataService.keyValSetObject('defaultCountry', countryvalid[0].value).then(data => {
                      if(this.Platform == 'web'){
                        this.loadingController.getTop().then(data=>{
                        })
                      this.dismissInstallLoading().then(data=>{
                      });
                      }
                      resolve(true);
                 // })
              }else{
              this.dataService.setObject('defaultCountry', countryDropdown.data[0].value).then(data => {
                this.dataService.defaultCountry = countryDropdown.data[0].value;
                this.dataService.setObject('defaultCountryCode', countryDropdown.data[0]?.id).then(data => {
                  this.dataService.defaultCountryId = countryDropdown.data[0]?.id;
                  this.dataService.defaultCountry = countryDropdown.data[0].value;
                  if(this.Platform == 'web'){
                    this.loadingController.getTop().then(data=>{
                    })
                  this.dismissInstallLoading();
                  }
                  resolve(true);
                })
  
              })
            }
        })
             
            })

          }

        })

      });
      this.dataService.mobileOfflineSyncApi().then(async apiData => {
        apiData.data.forEach((element: any, index) => {
          this.dataService.keyValSetObject(`${element.table_name}_table`, this.currentDate.toString()).then(insdata => {

          })
        });
      })
    })
  }

  initializeCountry(): Promise<any> {
    return new Promise((resolve) => {
      this.diagnostic.isLocationEnabled().then(isLocData => {
        if (isLocData) {
          this.splash();
          this.LocationEnabled().then(data => {
            resolve(data);
          })
        } else {
          this.message = this.mobileUiText.id_36;

          this.splash();
          this.gpsSettings = true;
          this.locationController('');
        }
      })
      App.addListener('appStateChange', ({ isActive }) => {
        if (isActive) {
          this.diagnostic.isLocationEnabled().then(data => {
            if (!data) {
              if (this.locationAlert) {
                this.locationAlert.dismiss();
              }
              this.locationController('');
            } else {
              if (this.gpsSettings) {
                this.LocationEnabled().then(data => {
                  resolve(true);
                })
                this.gpsSettings = false;
              } else if (this.isDeniedAlways) {
                if (this.locationAlert) {
                  this.locationAlert.dismiss();
                }
                this.diagnostic.getLocationAuthorizationStatus().then(async locAuthStatus => {

                  if (locAuthStatus === constants.isLocationGranted || locAuthStatus === constants.isLocationGRANTED_WHEN_IN_USE
                    || locAuthStatus === constants.isLocationAuthorized_when_in_use) {
                    this.getLocationDetails().then(datagetlocauth => {
                      resolve(true);
                      return true;
                    })
                  }
                  else if (locAuthStatus === constants.isDeniedAlways) {
                    this.message = this.mobileUiText.id_37;
                    this.locationController('App');
                  }
                }).catch(error => {
                  console.error('getLocationAuthorizationStatus Error:');
                  resolve(false);
                });
              } else {
               resolve(false);
              }

            }
          })
        }
      });
    })
  }
  LocationEnabled() {
    return new Promise((resolve) => {

      this.diagnostic.requestLocationAuthorization().then(async locAuthStatus => {

        if (locAuthStatus === constants.isLocationGranted || locAuthStatus === constants.isLocationGRANTED_WHEN_IN_USE ||
          locAuthStatus === constants.isLocationAuthorized_when_in_use) {


          this.getLocationDetails().then(sata => {

            resolve(sata);
          })
        } else if (locAuthStatus === constants.isDeniedAlways) {

          this.isDeniedAlways = true;
        }
        else if (locAuthStatus === 'DENIED_ONCE') {
          this.LocationEnabled();
        }
        else if (locAuthStatus === 'denied_always') {
          this.getLocationDetails().then(datagetlocauth => {
            resolve(true);
            return true;
          })
        }
      }).catch(error => {
        console.error('requestLocationAuthorizationStatus Error:');
      });
    })
  }
  //function for Device location settings
  switchToLocationSettings() {
    this.diagnostic.switchToLocationSettings();
  }
  //function for App location Permission settings
  switchToAppSettings() {
    this.diagnostic.switchToSettings().then(async switchApp => {
    })
  }
  // To Get Location Details coordinates like latitude and longitude
  getLocationDetails = async () => {
    return new Promise((resolve) => {
       this.presentLoading();
      Geolocation.getCurrentPosition({ enableHighAccuracy: true }).then(cords => {
        if (cords) {
          const latitude = cords.coords.latitude;
          const longitude = cords.coords.longitude;
          if ((this.Platform === 'android') || (this.Platform === 'ios')) {
          this.LocationGeocoder(latitude, longitude).then(cdata => {
            resolve(cdata);
          })
        }else{
          this.dataService.LocationAPi(latitude,longitude).subscribe(data=>{
              resolve(data.address.country);
              });
        }
       }  
      }).catch(error => {
        console.error('getCurrentPosition Error:', error);
        // this.dataService.keyValSetObject('defaultCountry', 'All Products').then(data => {

        //   resolve('All Products');

        // });
     //    if(this.Platform == 'web'){
        this.dataService.getObject('defaultLangCode').then((defLangCode: any) => {
          this.dataService.mobileCountryApi(defLangCode.value).then((apidata) => {
            if (apidata.data) {
        this.dataService.setObject('defaultCountry', apidata.data[0].value);
        this.dataService.defaultCountry = apidata.data[0].value;
        this.dataService.setObject('defaultCountryCode', apidata.data[0]?.id)
        this.dataService.defaultCountryId = apidata.data[0]?.id;
        this.dataService.defaultCountry = apidata.data[0].value;
        resolve(apidata.data[0].value);  
            }
          })
        })
    //  }
      })
    })
  };
  async presentLoading() {

    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait.',
      duration: 8000,
    });
    await this.loading.present().then(data=>{
      this.dataService.activeLoaders.push(this.loading);
    });
  }

  async dismiss() {
    await  setTimeout(() => {
      this.loadingController.dismiss();
       },2000);
  }
  // To Get country name and so on using Coordigates taken.
  LocationGeocoder(latitude, longitude) {
    return new Promise((resolve) => {
      this.nativeGeocoder.reverseGeocode(latitude, longitude, this.optionGeocoder)
        .then(async (result: NativeGeocoderResult[]) => {
          if (result) {
            const country = result[0].countryName;
            this.dataService.getObject('defaultLangCode').then((defLangCode: any) => {
              this.dataService.mobileCountryApi(defLangCode.value).then((apidata) => {
                if (apidata.data) {
                   let countryvalid = apidata.data.filter(i => i.countries && i.countries.some(i=>i.value===country) )
                    if(countryvalid.length){
                        this.dataService.setObject('defaultCountry', countryvalid[0].value)                      
                        this.dataService.defaultCountry = countryvalid[0].value;                                            
                        this.dataService.setObject('defaultCountryCode',countryvalid[0].id )
                        this.dataService.defaultCountryId= countryvalid[0].id;
                        this.dataService.setObject('defaultRegion',countryvalid[0].value)
                        this.dataService.defaultRegion=countryvalid[0].value;
                        resolve(country);
                    } else {
                         this.dataService.setObject('defaultCountry', apidata.data[0].value);
                         this.dataService.defaultCountry = apidata.data[0].value;
                         this.dataService.setObject('defaultCountryCode', apidata.data[0]?.id)
                         this.dataService.defaultCountryId = apidata.data[0]?.id;
                         this.dataService.defaultCountry = apidata.data[0].value;
                         resolve(apidata.data[0].value);   
                    }
                    this.dismiss();
                } 
              })
            })
          }
        }).catch((error: any) => {
          console.error('LocationGeocoder Error:', error);
        });
    });
  }
  async splash() {
    try {
      await StatusBar.setStyle({ style: Style.Dark });
      await StatusBar.setBackgroundColor({ color: '#e2000f' });
      await SplashScreen.hide();
    } catch {
      // StatusBar only available on mobile devices// StatusBar.setBackgroundColor Only available on Android
    }
  }

  // Alert Notification for location not enabled and having settings button
  async locationController(settingsInput) {
    this.locationAlert = await this.alertController.create(
      {
        subHeader: this.mobileUiText.id_38,
        message: this.message,
        backdropDismiss: false,
        buttons: [{
          text: this.mobileUiText?.id_7,
          role: this.mobileUiText.id_40,
          handler: () => {
            if (settingsInput === 'App') {
              this.switchToAppSettings();
            }
            else {
              if (Capacitor.getPlatform() === 'ios') {
                this.switchToAppSettings();
              }
              this.switchToLocationSettings();
            }
          },
        }],
      });
    await this.locationAlert.present();
  }
  async notifyController(uiText) {
    const alert = await this.alertController.create(
      {
        header: this.mobileUiText.id_47,
        message: uiText,
        backdropDismiss: false,
        buttons: [{
          text: this.mobileUiText.id_48,
          role: this.mobileUiText.id_48,
        }],
      });
    await alert.present();
  }

  //To check update for App by current  version of the App and latest
  async VersionCheck() {
    this.appVersion.getVersionNumber().then(async res => {
      if (res) {
        this.ionVersionNumber = res;
        if (Capacitor.getPlatform() === 'ios') {
          this.updateController(this.iosVersion, constants.appUpdateUrlIos);
        } else if (Capacitor.getPlatform() === 'android') {
          this.updateController(this.androidVersion, constants.appUpdateUrlAndroid);
        } else if (Capacitor.getPlatform() === 'web') {
          //for web functionality
        }
      }
    }).catch(error => { });
  }

  //Alert Notification for App Update and navigate to playstore. 
  async updateController(latestVersion, url) {
    if (this.ionVersionNumber < latestVersion) {
      this.dismissInstallLoading();
      const alert = await this.alertController.create(
        {
          header: this.mobileUiText.id_42,
          message: this.mobileUiText.id_43,
          backdropDismiss: false,
          buttons: [{
            text: this.mobileUiText.id_41,
            role: this.mobileUiText.id_41,
            handler: () => {
              Browser.open({ url: url });
              Browser.addListener('browserFinished', () => {
                this.browserClosed()
              });
            }
          }],
        });
      await alert.present();
    }
  }
  browserClosed() {
    this.VersionCheck();
  }
  async presentInstalLoading() {
    this.InstallLoading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait while syncing data from server.',
      duration: 5000,
    });
    await this.InstallLoading.present();
    this.dataService.activeLoaders.push(this.InstallLoading);
  }
  async dismissInstallLoading() {
    await  setTimeout(() => {
     this.loadingController.dismiss().then(data=>{
    })
      },10000);
  }
}
